// règles hors celles communes avec _archive.scss

.dc-tag {

  .pagination {
    margin-bottom: 3em;
  }

  #content-info {
    margin-bottom: 2em;
  }

  .tag-desc {
    font-size: 1.125rem;
    margin-bottom: 2em;
  }
}
