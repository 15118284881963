.dc-authors {
  #main {
    @media screen and(max-width: $medium-screen) {
      padding: 0;
    }
  }

  .simple {
    max-width: 58em;
    margin-top: 1em;
    padding: 1em .25em;
    background-color: #fff;

    @media screen and(min-width: $x-small-screen) {
      padding-left: .5em;
      padding-right: .5em;
    }

    @media screen and(min-width: $medium-screen) {
      padding-left: 1em;
      padding-right: 1em;
    }

    @media screen and(min-width: $large-screen) {
      padding-left: 2em;
      padding-right: 2em;
    }

    @media screen and(min-width: $x-large-screen) {
      padding: 2em;
    }
  }

  .perso-list {
    list-style-type: none;
    margin-top: 3em;
  }

.perso {
    padding-left: .5em;
    font-weight: bold;

    a {
      padding: 0 .25em;
    }

    a:link, a:visited {
      border-bottom-color: transparent;
    }

    a:focus {
      color: white;
    }
  }

  @each $nom,
  $valeur in $room-color {
    .#{$nom} {
      border-left: 1.5em solid $valeur;

      a {
        color: $valeur;
      }

      a:hover {
        border-bottom-color: $valeur;
      }

      a:focus {
        background-color: $valeur;
      }
    }
  }

}

.dc-author {
  .simple {
    box-shadow: none;
  }

  .chapter-separator {
    margin-bottom: .5em;
  }

  .chapter-title {
    font-size: 2.125rem;
  }

  .content-info-auth {
    padding-bottom: 1em;

    p {
      padding-left: 2rem;
      font-size: 1.25rem;
    }
  }

  .perso-sejour ul {
    list-style-type: none;
    padding-bottom: 0;
    margin-top: 0;
    font-size: 1.25rem;
  }

  .all-authors-link {
    @include discrete-link(lighten($body-color, 45%));
  }

  .post {
    position: relative; // pour permettre le clic sur toute la zone
    margin-bottom: 3rem;
  }

  .post-header {
    display: flex;

    p {
      font-size: .925rem;
      width: 5em;
      padding-top: .275rem;
    }

    .title {
      font-size: 1.125em;
      font-style: italic;
      font-weight: normal;

      a {
        color: white;
        border-bottom: transparent;
      }

      & a::after {
        display: block;
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .post-content {
    padding: .5rem 1.5rem;
  }
}
