@charset "UTF-8";

/** --------------------------------------------------
    Start
--------------------------------------------------- */
@import "init/config";              // variables
@import "init/mixins-functions";    // mixins, fonctions
@import "init/rebase";              // normalize & co.

/** --------------------------------------------------
    Common rules and layout
--------------------------------------------------- */
@import "partials/common";
@import "partials/layout";
@import "partials/header";
@import "partials/footer";

/** --------------------------------------------------
    Fragments
--------------------------------------------------- */
@import "partials/pagination";
@import "partials/post";
@import "partials/feedback";
@import "partials/a11yconfig";


/** --------------------------------------------------
    Pages
--------------------------------------------------- */
@import "partials/category";
@import "partials/search";
@import "partials/tag";
@import "partials/page";
@import "partials/faq";
@import "partials/archive";
@import "partials/author";
@import "partials/404";
