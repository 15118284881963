.dc-search {
  .simple {
    box-shadow: none;
  }

  .content-info {

    .nb-post-page {
      font-size: 1rem;
    }

    p {
      padding-left: 2rem;
      font-size: 1.25rem;
    }

    em {
      padding: .125rem .25rem;
      background-color: yellow;
    }
  }

  .post {
    margin-bottom: 3rem;
  }

  .post-header {
    display: block;

     > * {
      display: inline-block;
    }
  }

  .Notes-de-la-Direction {
    padding-left: 68px;
    background-size: 42px;
  }

  .post-content {
    padding: .5rem 1.5rem;
  }
}
