.pagination {
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  line-height: 2;

  ul {
    @include list-unstyled;
    @include discrete-link;

    margin: 0;

    > li {
      text-align: center;
    }

    @media screen and (min-width:$medium-screen) {
      display: flex;

      flex-wrap: wrap;
      justify-content: space-between;

      li {
        flex: 1;
      }

      .current-page {
        text-align: center;
      }

      .prev {
        text-align: left;
      }

      .next {
        text-align: right;
      }
    }
  }

  a {
    display: block;
    padding: .125em;
  }

  .current-page {
    padding: .125rem;
  }
}

.dc-home .pagination {
  margin-top: 1.5rem;

  @media screen and (min-width:$large-screen) {
    margin-top: 3rem;
  }
}

.dc-archive .pagination,
.dc-post .pagination {
  ul {
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
  }

  a {
    font-weight: 600;
  }
}

.dc-archive .pagination {
  li {
    text-align: center;
  }
}

.dc-tag .pagination {
  text-align: center;

  a {
    border-bottom: none;

    &:hover,
    &:focus {
      background-color: $link;
      color: white;
    }
  }
}
