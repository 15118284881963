// styles et positionnement du bouton

.footer-credits {
  position: relative;

  @media screen and(max-width: $small-screen) {
    padding-bottom: 3.5em;
  }
}

#accessconfig {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;

  #a42-ac-button {
    position: relative;
    left: -50%;
    background-color: darken($credits-bg,55%);
    color: #fff;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    &:hover,
    &:focus {
      background-color: $red;
      border-bottom: 0;
    }
  }
}
